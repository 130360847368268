import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import axiosInstance from '../../../Config/axios';
import { Slide, toast } from 'react-toastify';
import Toast from "../../../CommonComponent/Toast";
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import ToastContent from '../../../CommonComponent/Toast';

const WithdrawalPage = () => {
  const [isWithdrawalPending, setIsWithdrawalPending] = useState(false);
  const [withdrawalHistory, setWithdrawalHistory] = useState([]);
  const [withdrawableAmount, setWithdrawableAmount] = useState({
    balance: 0,
    taxAmount: 0,
  });
  const userData = useSelector(state => state.auth.userData);

  const fetchWithdrawalHistory = async () => {
    try {
      const response = await axiosInstance.post('/withdrawalhistory');

      if (!response.data.status) {
        throw new Error(response.data.message);
      }

      setWithdrawalHistory(response.data.data);
    } catch (err) {
      toast.error(<Toast message={err.message} status="error" />, {
        position: 'bottom-center', theme: 'dark', autoClose: 2000, transition: Slide
      });
    }
  };

  const getUserWithdrawableAmount = async () => {
    try {
      const response = await axiosInstance.get('user/withdrawable-amount');
      const responseData = response.data;

      if (!responseData.status) {
        throw new Error(responseData.message);
      }

      setWithdrawableAmount(responseData.data);
    } catch (error) {
      toast.error(<ToastContent status="Error" message={error.message} />, {
        autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, theme: 'dark', position: 'bottom-center'
      });
    }
  }

  useEffect(() => {
    getUserWithdrawableAmount();

    fetchWithdrawalHistory();
  }, []);

  const confirmWithdrawalRequest = async (data, setSubmitting) => {
    try {
      const bodyData = { orderId: data.orderId, amount: data.amount, status: data.status }

      const response = await axiosInstance.post('/confirm-withdrawal-request', bodyData);

      const { data: responseData } = response;
      if (!responseData.status) {
        throw new Error(responseData.message);
      }
      setSubmitting(false);
      fetchWithdrawalHistory();

      toast.success(<Toast message={'Withdrawal Successfully'} status="success" />, {
        position: 'bottom-center', theme: 'dark', autoClose: 2000, transition: Slide
      });
    } catch (error) {
      setSubmitting(false);
      toast.error(<Toast message={error.message} status="error" />, {
        position: 'bottom-center', theme: 'dark', autoClose: 2000, transition: Slide
      });
    }
  }

  const handleWithdrawalSigning = async (data, setSubmitting) => {
    try {
      const bodyData = { orderId: data.orderId, amount: data.amount, status: data.status }
      const response = await axiosInstance.post('/approve-withdrawal-request', bodyData);

      const { data: responseData } = response;
      if (!responseData.status) {
        throw new Error(responseData.message);
      }

      confirmWithdrawalRequest(data, setSubmitting);


    } catch (error) {
      setSubmitting(false);
      toast.error(<Toast message={error.message} status="error" />, {
        position: 'bottom-center', theme: 'dark', autoClose: 2000, transition: Slide
      });
    }
  }


  const handleSubmit = async (values, setSubmitting, resetForm) => {
    
    if (Number(values.amount) > withdrawableAmount?.balance) {
      toast.error(<ToastContent status="Error" message="Withdrawable amount is less than the requested amount" />, {
        autoClose: 5000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, theme: 'dark', position: 'bottom-center'
      });
      setSubmitting(false);
      return;
    }
    try {

      setSubmitting(true);
      const response = await axiosInstance.post("/withdrawalrequest", values);

      const { data: responseData } = response;
      if (!responseData.status) {
        throw new Error(responseData.message);
      }


      handleWithdrawalSigning(responseData.data, setSubmitting);

      // resetForm();
      // fetchWithdrawalHistory();
    } catch (error) {
      setSubmitting(false);
      toast.error(<Toast message={error.message} status="error" />, {
        position: 'bottom-center', theme: 'dark', autoClose: 2000, transition: Slide
      });
    }

  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      amount: '',
    },
    validationSchema: yup.object({
      amount: yup.number().required('Required').min(withdrawableAmount?.taxAmount, `Minimum amount is ${withdrawableAmount?.taxAmount}`),
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      handleSubmit(values, setSubmitting, resetForm);
    },
  })

  return (
    <div className=" overflow-auto h-[100%]  justify-center p-2 gap-5" >
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.3 }}
        className="bg-[#1d1122] p-6 rounded-lg shadow-lg  "
      >
        <h2 className="text-white text-xl mb-6">Withdrawal</h2>
        <form onSubmit={formik.handleSubmit}>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            <div className='w-full'>


              <div className="mb-4 w-full">
                <label htmlFor="amount" className="text-white block mb-2">Amount</label>
                <input
                  type="number"
                  name='amount'
                  id="amount"
                  value={formik.values.amount}
                  onChange={formik.handleChange}
                  className="bg-gray-800 text-white py-2 px-4 rounded-md w-full"
                  placeholder={`Enter amount`}
                />
                {formik.touched.amount && formik.errors.amount && (
                  <p className="text-red-500 text-sm mt-1">{formik.errors.amount}</p>
                )}
              </div>

            </div>
          </div>
          <motion.button
            className={`bg-green-600 py-2 hover:bg-green-700 text-white  px-8 rounded-lg block shadow-md ${isWithdrawalPending ? 'cursor-not-allowed opacity-50' : ''}`}
            type="submit"
            disabled={formik.isSubmitting}
          >
            {formik.isSubmitting ? 'Please Wait...' : 'Withdraw'}
          </motion.button>


        </form>


        <div className="bg-gray-600 text-white p-4 mt-4 rounded-lg">
          <p className="font-medium">You can withdraw upto {Number(withdrawableAmount?.balance).toFixed(6)} SOL</p>
          <p className="text-sm mt-2">
            Deduction of 10% of the amount will be made for the game won as a platform fee.
            <br />
            <br />
            Here is the breakdown of the amount:
            <br />
            <br />

            <span className='font-bold'>Deposit By You - Withdrawal By You - Lost In Game + (Won In Game * 90%)</span>
            </p>
        </div>
        <div className="bg-gray-600 text-white p-4 mt-4 rounded-lg">
          <p className="font-medium">Notice:</p>
          <p className="text-sm mt-2">

            Please fill the amount and submit the withdrawal request. After verification, the amount will be transferred to your wallet address <span className='font-bold text-green-500'>{userData?.address}</span>.

            <br />
            <br />
            <span className='text-red-500'>Please don't refresh the page after submitting the withdrawal request until the transaction is confirmed on-chain. In case of any issues, please contact the administration.</span>
          </p>
        </div>
      </motion.div>

      {/* Withdrawal History */}
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.3 }}
        className="bg-[#1d1122] p-6 rounded-lg shadow-lg "
      >
        <h2 className="text-white text-xl mb-6">Withdrawal History</h2>
        <hr className='border-white mb-3' />
        <div className="overflow-x-auto ">
          <table className=" s p-3 text-sm w-full">
            <thead>
              <tr>
                <th className="px-1 py-2 text-center text-white ">Amount (Sol)</th>
                <th className="px-1 py-2 text-center text-white">Order Id</th>
                <th className="px-1 py-2 text-center text-white">Status</th>
                <th className="px-1 py-2 text-center text-white">Date</th>
              </tr>
            </thead>
            <tbody>
              {withdrawalHistory.length === 0 ? (
                <tr>
                  <td colSpan="3" className="px-4 py-2 text-center text-gray-400">No withdrawal history available</td>
                </tr>
              ) : (
                withdrawalHistory.map((withdrawal) => (
                  <tr key={withdrawal.id}>
                    <td className="px-4 py-2 text-center text-white">{withdrawal.amount} </td>
                    <td className="px-4 py-2 text-center text-white">{withdrawal.orderId}</td>
                    <td className="px-4 py-2 text-center text-white">{withdrawal.status}</td>
                    <td className="px-4 py-2 text-center text-white">{new Date(withdrawal.createdAt).toLocaleDateString()}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </motion.div>
    </div>
  );
};

export default WithdrawalPage;
